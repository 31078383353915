@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Color
$blur_bk:rgba(0,0,0,.5);
$d_red:#0b042c;
$l_red:#123666;
$box_red: #e2e2e2;
$box_accent:rgb(46, 97, 173);
$box_text:#141a70;
// Text
$text: #fff;
// Font
$heading:'Noto Sans Display', times, sans-serif;
$body:'Noto Sans Display', times, sans-serif;
// align
$view_width: 100ch;
$section_padding: 6rem 1rem;
$mobile_section: 2rem 1rem;

@mixin button(){
  padding:.25rem 1.25rem;
  margin:1rem;
  font-weight: 500;
  background: rgb(46,34,213);
  background: linear-gradient(90deg, rgba(46,34,213,1) 0%, rgb(65, 128, 223) 100%);
  border-radius: 10px;
  transition: all .5s ease-out;
}
*{
  box-sizing: border-box;
  margin:0; padding:0;
  color:$text;
}
html { 
  scroll-behavior: smooth; 
}
.App{
  position:relative;
  font-family: $heading;
  // height: 3000px;
}
.navigation{
  position:fixed;
  top:0px;
  background-color: rgba(25, 26, 80, 0.75);;
  // display:flex;
  // justify-content: center;
  width:100%;
  padding: 1rem 0;
  z-index: 99;

  ul{
    position:sticky;
    z-index: 10;
    margin: 0 auto;
    color: $text;
    max-width:$view_width;
    display:flex;
    justify-content: space-between;
    align-items: center;
    img.logoImg{
      width:35px; height:auto;
    }
    li{
      list-style-type: none;
      padding:0 1rem;
      font-weight: 500;
      img.hambuger{
        display:none;
      }
    }
    li.title{
      flex:3;
    }
  }
  a{
    text-decoration: none;
    color:$text;
    &:visited{
      color:$text;
    }
    
  }
}
a.title{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.25rem;
  font-style: italic;
  img{
    margin-right: .5rem;
  }
  // span{
  //   background: linear-gradient(270deg, rgba(46,34,213,1) 0%, rgb(175, 13, 8) 100%);
  // }
}

div.topSection{
  background: rgb(0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 1080px;
  position: relative;
  z-index: 1;
  div.videoWrapper{
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    video.videoTop{
      width:100%;
      height:100%;
      object-fit: cover;
      filter:brightness(70%)
    }
  }
  div.topText{
    z-index: 5;
    color:$text;
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img{
      width:150px;
      height:auto;
    }
    h1{
      margin-bottom: 2rem;
      font-style: italic;
    }
    h2{
      font-style: italic;
    }
    h3{
      font-weight: 400;
    }
  }
}
a.button{
  @include button();
  text-decoration: none;
  &:hover{
    cursor: pointer;
    background: linear-gradient(270deg, rgba(46,34,213,1) 0%, rgb(8, 100, 175) 100%);
    transition: .5s ease-in-out;
  }
}

div#about{
  width:100%;
  padding: $section_padding;
  background-color: $d_red;
  article.aboutArea{
    margin:0 auto;
    max-width:$view_width;
    display:flex;
    justify-content: space-between;
    align-items: center;
    img{
      width:300px; height:300px;
      border-radius: 50%;
      margin: 2rem;
    }
    div.aboutText{
      padding:1rem;
      h2{
        font-size: 2rem;
      }

      p{
        margin:1rem 0;
      }
    }
  }
}

div#technologies{
  background-color:$l_red;
  padding: $section_padding;
  div.techWrapper{
    max-width: $view_width;
    margin:0 auto;
    text-align: center;
    h2{
      font-size: 3rem;
    }
    ul.techStack{
      display:flex;
      width:100%;
      justify-content: space-evenly;
      margin: 2rem 0;
      li{
        list-style-type: none;
        img{
          width:70px; height: 70px;
          border-radius: 14px;
        }
        &:hover{
          transform:scale(1.25);
          transition: all .25s ease-in-out;
        }
      }
    }
  }
  section{
    max-width: $view_width;
    margin:0 auto;
    display:flex;
    article{
      margin:0 1rem;
      width:100%;
      text-align: center;
      background-color: $box_red;
      h3{
        padding:1rem;
        background-color: $box_accent;
      }
    }
  }
}
div.techList{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  padding:1rem;
  align-items: center;
  span{
    margin:.5rem;
    padding: .1rem .25rem;
    color:$box_accent;
    font-weight: 600;
    border:1px solid $box_accent;
    border-radius: 5px;
    &:hover{
      border:1px solid $box_accent;
      background-color:$box_accent;
      color:$text;
      transform: scale(1.1);
      transition: .25s all ease-in-out;
    }
  }
}
div#projects{
  background-color:$d_red;
  padding: $section_padding;
  div.projectText{
    max-width: $view_width;
    margin:0 auto;
    margin-bottom: 2rem;
    text-align: center;
    h2{
      font-size: 3rem;
    }
  }
  h3.projectSubTitle{
    text-align: center;
    font-size: 2rem;
  }
}
section.webDev{
  h4{
    width:100%;
    font-size: 1.25rem;
    text-align: center;
    padding:1rem;
    background-color: $box_accent;
  }
  ul{
    display:flex;
    // justify-content: center;
    // flex-wrap: wrap;
    overflow-x: scroll;
    margin:2rem 0;
    margin-bottom: .5rem;
    li{
      background-color: $box_red;
      list-style-type: none;
      min-width: 35ch;
      display:flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      margin:1rem;
      img.webProjectImg{
        width:90%; height:90%;
        max-height: 163px;
        margin:1rem;
        &:hover{
          cursor: pointer;
          filter: brightness(0.75);
          transition: .5s ease-in-out;
        }
      }
      &:hover{
        box-shadow: 0 0 2px 1px;
        transition: all .25s ease-in-out;
      }
    }
  }
  ul::-webkit-scrollbar {
    height: 6px;
    width: 80vw;
  }
  ul::-webkit-scrollbar-track {
    background: rgba(255,255,255,.5);
  }
  ul::-webkit-scrollbar-thumb {
    background: $box_red; 
  }
  div.arrows{
    display:flex;
    justify-content:center;
    align-items: center;
    span{
      display:flex;
      align-items: center;
      padding:.5rem;
      opacity:.7;
      &:nth-of-type(1){
        transform: rotate(180deg);
      }
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
div.webProjectText{
  padding:0 1rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  p, span{
    color:$box_text;
    font-weight: 500;
    text-align: center;
  }
  p:nth-of-type(1){
    font-style: italic;
    margin-bottom: 1rem;
  }
  p.desc{
    font-size: .9rem;
  }
  p:nth-of-type(3){
    margin: 1rem 0;
  }
  span{
    padding:.25rem;
    margin:0.25rem;
    font-size: .75rem;
    display:inline-block;
    border-radius: 5px;
    background-color: $box_accent;
    color:$text;
    &:hover{
      transform: scale(1.15);
      transition: .15s all;
    }
  }
}
div.projectLink{
  padding: .5rem;
  background-color: $box_accent;
  display:flex;
  align-items: center;
  width:100%;
  justify-content:space-around;
  a{
    display:flex;
    justify-content: center;
    width:100%;
    img:hover{
      filter:brightness(500%);
      transition: .5s all;
    }
  }
}
// DETAIL WINDOW
div.detail{
  padding: 2rem;
  display:flex;
  justify-content: center;
  background-color:$box_accent;
  border-radius: 15px;
  max-width: 80ch;
  margin: 0 auto;
  h3{
    font-style: italic;
  }
  div.detailText{
    margin-left:1rem;
    p{
      font-size: .85rem;
    }
  }
}
section.uiux{
  margin: 2rem 0;
  ul{
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    max-width:$view_width;
    margin:2.5rem auto;
    li{
      list-style-type: none;
      display:flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      margin:1rem 0;
      a{
        display:flex;
        justify-content: center;
      }
      img{
        width:80%;
        border-radius: 20px;
      }
    }
  }
}
h2.h2Title{
  font-size: 3rem;
}
div#contact{
  background-color: $box_text;
  padding: $section_padding;
  article{
    width:$view_width;
    margin: 0 auto;
  }
}
div.links{
  margin:.5rem 0;
  a{
    margin-right: .5rem;
    img{
      filter:brightness(500%)
    }
  }
}
footer{
  width: 100%;
  background-color: $l_red;
  margin:0;
  ul{
    max-width: $view_width;
    margin:0 auto;
    padding:.75rem 0;
    display:flex;
    justify-content: space-between;
    li{
      list-style-type: none;
    }
    li:nth-of-type(2){
      font-style: italic;
    }
    li:nth-of-type(3){
      display:flex;
      a{
        margin-left:.5rem;
        filter:brightness(300%);
      &:hover{
        transform:scale(1.1);
      }
      }
    }
  }
}
@media screen and (max-width:800px){
  div#root{
    width:100%;
    overflow: hidden;
  }
  nav{
    display:block;
    padding-top:0 !important;
    padding-bottom:0 !important;
    ul{
      flex-direction: column;
      align-items: center;
      li.title{
        display: block;
        background-color: rgba(0,0,0,.5);
        display:flex;
        align-items: center;
        padding: 1rem !important;
        a{
          justify-content:flex-start
        }
        img{
          display:block !important;
          width: 30px;
          height: 25px;
        }
      }
      li{
        display:none;
        width: 100%;
        margin:0 auto;
        padding: 1rem 0 !important;
        a{
          display:block;
          width:100%;
          text-align: center;
          margin:0 auto;
        }
        &:hover{
          background-color: rgba(0,0,0,.35) !important;
          transition: .25s all ease-in-out;
        }
      }
      li.expand{
        display:block;
        
      }
    }
  }
  div#about{
    padding: $mobile_section;
    article.aboutArea{
      flex-direction: column;
      img{
        width:80%; height:auto;
        max-width: 300px;
        max-height: 300px;
        border-radius: 10%;
        margin:0 auto;
      }
      div.aboutText > h2{
        font-size: 2rem;
      }
      p{
        font-size: .9rem;
      }
    }
  }
  h2.h2Title{
    font-size: 2rem !important;
  }
  div#technologies{
    padding: $mobile_section;
    section{
      flex-direction: column;
      align-items: center;
      margin:1rem;
      // padding:1rem;
      article{
        margin:1rem 0;
        min-height: 210px;
        max-width: 500px;
      }
    }
  }
  ul.techStack{
    margin-bottom: 0;
    li{
      img.techIcons{
        border-radius: 50%;
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
  div#projects{
    padding: $mobile_section;
  }
  h3.projectSubTitle{
    font-size: 1.5rem !important;
  }
  div.detail{
    flex-direction: column;
    align-items: center;
    img{
      max-width: 300px;
    }
    div.detailText{
      margin:0;
      padding: 1rem 0;
    }
  }
  img.uiuxview{
    min-width:100%;
  }
  div#contact{
    padding: $mobile_section;
    h2.contactTitle{
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p{
      max-width: 35ch;
    }
  }
  footer{
    ul{
      flex-direction: column;
      align-items: center;
      padding:.5rem;
      text-align: center;
      li{
        margin:.25rem 0;
      }
      li.copyright{
        font-size: .85rem;
      }
    }
  }
}